<!-- Settings -->
<template>
  <div class="card card-custom product_ct">
    <div class="card-body">
	    <div class="d-flex mb-9">		   
		  <div class="flex-grow-1">
			  <div class="d-flex justify-content-between flex-wrap mt-1">
				  <div class="d-flex mr-3">
              <span class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3">{{view.name}}</span>
              <span v-if="view.record_status ==1"><i class="flaticon2-correct text-success font-size-h5"></i></span>
          </div>				
			</div>
			<div class="d-flex flex-wrap justify-content-between mt-1">
				<div class="d-flex flex-column flex-grow-1 pr-8">
					<div class="d-flex flex-wrap mb-4">
              <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-layers-2 mr-2 font-size-lg"></i>SKU : {{view.sku}}</span>
              <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-interface-9 mr-2 font-size-lg"></i>Brand : {{view.brand_model}}</span>            
              <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-list-2 mr-2 font-size-lg"></i>Category : {{view.category_name ? view.category_name:''}}</span>                
          </div>         
                                
         </div>				
			</div>
		</div>
	</div>
  <div>
     <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
      <div class="symbol symbol-50 symbol-lg-120">
        <span class="index-image employee-image" v-loadimage="view.images"></span>
      </div>			
    </div>
  </div> 
    <el-form>
      <div class="row" >
            <div class="col-xl-12">
                <div class="row">
                    <div class="col-xl-12">
                        <h4>Price Description</h4>
                    </div>
                </div>            
                <div class="row">
                  <div class="col-xl-12">
                      <span class="font-weight-bold text-dark">{{view.description}}</span> 
                  </div>
                </div>
            </div>
      </div>   
         <hr/>
         <div class="row">
            <div class="col-sm-5">
              <div class="row">
                <div class="col-sm-6">
                  <div class="profile-attr">
                    <label><i class="flaticon2-hexagonal"></i>weight</label>
                    <p>{{view.weight != null ? view.weight : '-'}} Kg</p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="profile-attr">
                    <label><i class="flaticon2-line"></i>Length</label>
                    <p>{{(view.length) ? view.length : '-'}} inch</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-7">
              <div class="row">
                <div class="col-sm-6">
                  <div class="profile-attr">
                    <label><i class="flaticon2-size"></i>Width</label>
                    <p>{{view.width}} inch</p>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="profile-attr">
                    <label><i class="flaticon2-arrow-1"></i>Height</label>
                    <p>{{view.height}} inch</p>
                  </div>
                </div>              
              </div>
            </div>
         </div>
         <hr/>
         
    <div class="row" >
        <div class="col-xl-12">
            <div class="row">
                <div class="col-xl-12">
                    <h4>Price Details</h4>
                </div>
            </div>            
            <div class="row">
                <div class="col-sm-8">
                  	<div class="d-flex flex-wrap justify-content-between mt-1">
                      <div class="d-flex flex-column flex-grow-1 pr-8">
                        <div class="d-flex flex-wrap mb-4">
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-layers-2 mr-2 font-size-lg"></i><span class="font-size-h4 font-weight-bolder">Square Feet : {{view.sq_feet}}</span>(sq.ft)</span>                      
                         
                        </div>                        
                        <div class="d-flex flex-wrap mb-4" v-for="(input,k) in supplier" :key="k">
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-rotate mr-2 font-size-lg"></i>Supplier {{(k+1)}} : {{input.supplier_details.company_name}}</span>                       
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-interface-9 mr-2 font-size-lg"></i>Supplier ID {{(k+1)}} : {{input.supplier_id}}</span> 
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Supplier Cost {{(k+1)}} : ${{input.supplier_cost}}</span>                                             
                        </div> 
                        <div class="d-flex flex-wrap mb-4">
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i><span class="font-size-h4 font-weight-bolder"> Cost Price : ${{view.cost_price}}</span></span>    
                        </div>     
                        <div class="d-flex flex-wrap mb-4">
                           <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-list mr-2 font-size-lg"></i>Regular Discount off(%) :{{view.regular_per}}</span>
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Regular Price: ${{view.regular_price}}</span>
                          <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Special Price: ${{view.special_price}}</span>
                        </div>     
                      </div>                   
                    </div> 
                </div>          
                <div class="col-xl-4">
                  <div class="d-flex flex-wrap justify-content-between mt-1">
                    <div class="d-flex flex-column flex-grow-1 pr-8">
                        <div class="d-flex flex-wrap mb-4">
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-list mr-2 font-size-lg"></i>Tax : {{view.tax_name}} {{view.tax_amount}} %</span>                       
                        </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>       
    </div>
    <div class="row">      
           <div class="col-xl-4">
            <div class="row">
                <div class="col-xl-12">
                    <h4>Contractor</h4>
                </div>                
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="d-flex flex-wrap justify-content-between mt-1">
                    <div class="d-flex flex-column flex-grow-1 pr-8">
                        <div class="d-flex flex-wrap mb-4">
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-list mr-2 font-size-lg"></i>Discount Off: {{view.contractor_discount}} %</span>
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Contractor Sale Price: ${{view.contractor_price}}</span>
                                    
                        </div>
                    </div>
                </div>
              </div>                             
            </div>
        </div>
        <div class="col-xl-4">
            <div class="row">
                <div class="col-xl-12">
                    <h4>Dealer</h4>
                </div>                
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="d-flex flex-wrap justify-content-between mt-1">
                    <div class="d-flex flex-column flex-grow-1 pr-8">
                        <div class="d-flex flex-wrap mb-4">
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-list mr-2 font-size-lg"></i>Discount Off: {{view.dealer_discount}} %</span>
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Dealer Sale Price: ${{view.dealer_amount}}</span>
                                    
                        </div>
                    </div>
                </div>
              </div>                             
            </div>
        </div>
         <div class="col-xl-4">
              <div class="row">
                <div class="col-xl-12">
                    <h4>Whole Sale Details</h4>
                </div>                
            </div>
             <div class="row">
                <div class="col-xl-12">
                  <div class="d-flex flex-wrap justify-content-between mt-1">
                    <div class="d-flex flex-column flex-grow-1 pr-8">
                        <div class="d-flex flex-wrap mb-4">
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-list mr-2 font-size-lg"></i>Discount Off : {{view.whole_discount}} %</span>                       
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon-price-tag mr-2 font-size-lg"></i>Whole Sale Price : ${{view.whole_amount}}</span>                                                   
                        </div>
                    </div>
                  </div>
                </div>
             </div>
           </div>      
    </div>
      <div class="row ">      
        <div class="col-xl-12">
            <div class="row">
                <div class="col-xl-12">
                    <h4>Inventory Details</h4>
                </div>                
            </div>
            <div class="row">
                <div class="col-xl-12">
                  <div class="d-flex flex-wrap justify-content-between mt-1">
                    <div class="d-flex flex-column flex-grow-1 pr-8">
                        <div class="d-flex flex-wrap mb-4">
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-delivery-package mr-2 font-size-lg"></i>In Stock : {{view.stock_quantity}}</span>                       
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-delivery-package mr-2 font-size-lg"></i>Reorder : {{view.reorder}}</span>
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-delivery-package mr-2 font-size-lg"></i>Minimum Quantity : {{view.min_quantity}}</span>
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-delivery-package mr-2 font-size-lg"></i>Maximum Quantity : {{view.max_quantity}}</span>
                            <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                              <i class="flaticon-interface-9 mr-2 font-size-lg"></i>
                              Stock Status : <span v-if="view.stock_quantity < view.min_quantity" class="red font-size-h4 font-weight-bolder">Low Stock</span> 
                                             
                            </span>
                        </div>           
                                                   
                    </div>				
                  </div>
                </div>  
            </div>
        </div>       
                  
        <div class="col-xl-12">
                <div class="row">
                    <div class="col-xl-12">
                        <h4>Product Details</h4>
                    </div>
                </div>               
                <div class="row">
                   <div class="col-xl-6"> 
                      <div class="d-flex flex-wrap justify-content-between mt-1">
                            <div class="d-flex flex-column flex-grow-1 pr-8">
                                <div class="d-flex flex-wrap mb-4" v-if="view.inventory_prod ==='1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Inventory product</span>                       
                                </div>
                                 <div class="d-flex flex-wrap mb-4" v-if="view.service_req === '1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Service required</span>                       
                                </div>
                                <div class="d-flex flex-wrap mb-4" v-if="view.reg_customer === '1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Registered customer</span>                       
                                </div>
                                 <div class="d-flex flex-wrap mb-4" v-if="view.ask_serial === '1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Ask serial number when sold</span>                       
                                </div>
                                 <div class="d-flex flex-wrap mb-4" v-if="view.refund_exchange === '1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Refundable / Exchangeable</span>                       
                                </div>
                                 <div class="d-flex flex-wrap mb-4" v-if="view.is_gift === '1'">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-check-mark mr-2 font-size-lg text-success"></i>Is a gift Card</span>                       
                                </div>
                            </div>
                      </div>  
                   </div>                  
                   <div class="col-xl-6">  
                       <div class="d-flex flex-wrap justify-content-between mt-1">
                            <div class="d-flex flex-column flex-grow-1 pr-8">
                                <div class="d-flex flex-wrap mb-4"  v-for="(input,k) in view.mgf_list" :key="k">
                                    <span class="text-dark  font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"><i class="flaticon2-delivery-package mr-2 font-size-lg"></i>MGF Model Number {{(k+1)}}: {{input.mgf_model}}</span>                       
                                </div>                                                            
                            </div>
                       </div>   
                    </div>
                                                 
                </div>          
            </div>
        </div>
    </el-form>
   </div>
 </div>         
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getProduct} from "@/api/product";

export default {
  name: 'view-product',
  
   data() {
    return {
      loading: false,
      view:[],
      title_text: 'Product Details',
      supplier:[],

    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Product List", route: "/product/list" },
      { title: "View Product" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  
   methods: {
  fetchData() {
      this.loading = true
      getProduct(this.$route.params.product_id).then(response => {
        this.view = response.data.data
        this.supplier = response.data.data.supplier_list;
        this.loading = false
      });
    },
    editProduct(id){
    this.$router.push({path:'/product/edit/'+id})
    },
   }
   
};
</script>
<style scoped>
.el-form h4:before {
    content: "";
    height: 2px;
    width: 60px;
    background: #3699FF;
    position: absolute;
    bottom: -1.25px;
    z-index: 1;
}

</style>